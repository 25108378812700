<template>
  <layout content-class="pa-0">
    <template>
      <account-menu-header />
      <v-divider />
      <account-menu-list />
    </template>

    <template #footer>
      <v-spacer />
      <v-btn color="primary" text @click.stop="$emit('close')">
        Close
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import AccountMenuHeader from '@/layouts/map/headerMenu/AccountMenuHeader.vue';
import AccountMenuList from '@/layouts/map/headerMenu/AccountMenuList.vue';

export default {
  name: 'AppSelectMobile',
  components: {
    Layout,
    AccountMenuHeader,
    AccountMenuList
  }
};
</script>

<style lang="sass"></style>
