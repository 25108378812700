<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-avatar size="40" color="primary">
        <span class="white--text" style="text-transform: uppercase;">{{
          firstLetters
        }}</span>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="headline font-weight-medium">
        <div v-testid="'account-name'" class="caption-2">{{ fullName }}</div>
      </v-list-item-title>
      <v-list-item-subtitle>
        <div v-testid="'account-email'" class="body-2 text--secondary">
          {{ email }}
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { useAuth } from '@/modules/auth/api';

export default {
  name: 'AccountMenuHeader',
  setup() {
    const { firstLetters, email, fullName } = useAuth();
    return {
      email,
      firstLetters,
      fullName
    };
  }
};
</script>

<style></style>
