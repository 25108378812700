import { useAuth } from '@/modules/auth/api';
import downloadBlob from '@/utils/downloadBlob';
import useMedia from '@/compositions/media';
import { useObjects } from '@/modules/objects/compositions/objects';

export default function() {
  const { logout, user } = useAuth();
  const { getNotificationsHistory } = useMedia();
  const { resultList } = useObjects();

  const accountMenuItems = [
    {
      id: 'edit_profile',
      title: 'edit profile',
      icon: '$edit',
      action: ({ popup }) => {
        popup.open({
          component: () => import('./EditAccount.vue')
        });
      }
    },
    {
      id: 'change_password',
      title: 'change password',
      icon: '$password',
      action: ({ popup }) => {
        popup.open({
          component: () => import('./ChangePassword.vue'),
          props: {
            userId: user.value?.id
          }
        });
      }
    },
    {
      id: 'program_settings',
      title: 'program settings',
      icon: '$settings',
      action: ({ popup }) => {
        popup.open({
          component: () => import('./ProgramSettings.vue')
        });
      }
    },
    {
      id: 'notifications_settings',
      title: 'notifications settings',
      icon: '$message_alert',
      action: ({ popup }) => {
        popup.open({
          component: () => import('./NotificationSettings.vue')
        });
      }
    },
    {
      id: 'notifications_downlod',
      title: 'download notifications',
      icon: '$download',
      action: ({ popup }) => {
        popup.open({
          component: () =>
            import('@/components/history-list-card/HistoryDateRangePopup'),
          props: {
            onSubmit: async range => {
              const data = {
                from: `${range.from} 00:00:00`,
                to: `${range.to} 23:59:59`,
                ids: resultList.value.map(object => object.id)
              };
              await getNotificationsHistory(data).then(res => {
                downloadBlob(
                  res.data,
                  `PixelMonitor_NotificationsHistory_${range.from}-${range.to}.csv`,
                  res.data.type
                );
              });
            }
          }
        });
      }
    },
    {
      id: 'logout',
      title: 'logout',
      icon: '$exit_to_app',
      action: async () => {
        await logout();
      }
    }
  ];

  return {
    accountMenuItems
  };
}
